import { navigate } from 'gatsby'
import React from 'react'
import { isBrowser, setEventProperties, setPageProperties, setUserIdentities, setUserProperties } from '../../utils/zeotap'
import { ContextProviderComponent, SiteContext } from '../context/mainContext'
import { sha256 } from 'js-sha256';
import { isSymphony } from '../../utils/helpers';
import { FaBatteryQuarter } from 'react-icons/fa';
import { nanoid } from 'nanoid';

const EMAIL_KEY = 'EMAIL_SHA256';

class SignIn extends React.Component {
  signIn = this.props.context.signIn
  state = {
    username: '', email: '', password: ''
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  login = (isHashed = false) => {
    const event_id = nanoid();
    // window.fbq('trackCustom', 'login', { event_id, eventID: event_id, email: this.state.email, loginid: this.state.username, cellno: '9292929292' });
    if (!isSymphony()) {
      if (isHashed) {
        setUserIdentities({ email_sha1_lowercase: this.state.email, loginid_sha1_uppercase: this.state.username, cellno_without_country_code_md5: '9292929292' }, isHashed);
      } else {
        // window._satellite.setVar('Email', this.state.email)
        setUserIdentities({ email: this.state.email, loginid: this.state.username, cellno: '9292929292', fpuid: '4266c4c0-d349-4dfa-96eb-b8c8eaf9896e' }, isHashed);
      }
      setUserProperties({ id: event_id, age: 25, gender: 'Male', country: 'IND', countrycode: '91' });

    }
    else {
      setUserIdentities({ email: this.state.email });
      setUserProperties({});
    }
    setEventProperties("signedIn");
    window.localStorage.setItem(EMAIL_KEY, sha256(this.state.email.toLowerCase()));
    this.signIn();
    navigate('/home')
  }
  render() {
    return (
      <div>
        <div className="flex flex-1 justify-center">
          <div className="w-full max-w-144">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {!isSymphony() ? (<div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Username
                </label>
                <input aria-label="username"
                  onChange={this.onChange} name="username"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
              </div>) : null}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input aria-label="email"
                  onChange={this.onChange} name="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="Email" />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password
                </label>
                <input aria-label="password"
                  onChange={this.onChange} name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" />
              </div>
              <div className="flex items-center justify-between">
                <button onClick={() => this.login()} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                  Sign In
                </button>
                <button onClick={() => this.login(true)} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                  Hashed Sign In
                </button>
                <a className="inline-block alignx-baseline font-bold text-sm" href="login#" onClick={() => setEventProperties('forgotPassword')}>
                  Forgot Password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function SignInWithContext(props) {
  setPageProperties({ page_category: 'Login', page_name: 'Login', page_domain: isBrowser() && window.location.hostname })
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {
          context => <SignIn {...props} context={context} />
        }
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default SignInWithContext